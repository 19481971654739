import axios from 'axios'
// import store from '@/store'
// import { isCheckTimeout } from '@/utils/auth'
// console.log(process.env.VUE_APP_BASE_API)
const hostUrl = {
  development: {
    apiHost: 'http://18.143.169.158/',
    mintHost: "http://54.255.188.84:80"
  },
  test: {
    apiHost: 'http://18.143.169.158/',
    mintHost: "http://54.255.188.84:80"
  },
  production: {
    apiHost: 'http://18.143.169.158/',
    mintHost: "https://www.gulaba.com/api/"   //正式的改为 https://www.gulaba.com/api/
  }
}
const getRequestBaseUrl = (url) => {
  let tempKey = ''
  if (url.indexOf('/api') === 0) {
    tempKey = 'apiHost'
  } else if (url.indexOf('/gubala') === 0) {
    tempKey = 'mintHost'
  }
  return hostUrl[process.env.NODE_ENV][tempKey]
}


// const service = axios.create({
//   baseURL: getRequestBaseUrl(url),
//   timeout:  600000
// })


axios.interceptors.request.use(config => {
  config.baseURL = getRequestBaseUrl(config.url)
  // header设置token
  // baseURL: getRequestBaseUrl(url),
  config.headers['token'] = sessionStorage.getItem("setToken")

  // if(config.url.indexOf('/gubala') === 0 ){
  //   config.headers['token'] = localStorage.getItem("setGameToken")
  // }else{
  //   config.headers['timeholderToken'] = localStorage.getItem("setToken")
  // }
  // // 不是urlencoded的类型，主要是传文件的接口设置了特殊http请求类型，用接口传入的类型
  const contentType = config.headers && config.headers['Content-Type']
  if (contentType) {
    // console.log('???')
    config.headers['Content-Type'] = contentType
    // config.headers['Content-Type'] =  'application/json;charset=UTF-8'
  } else {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // 处理json转url
    if (config.data) {
      const searchParams = new URLSearchParams();
      const params = Object.keys(config.data)
      params.forEach(k => {
        // 处理多选
        if (typeof config.data[k] === Array) {
          config.data[k].map(a => {
            searchParams.append(k, a)
          })
        } else {
          searchParams.append(k, config.data[k])
        }
      })
      config.data = searchParams.toString()
    }
  }
  return config
}, error => {
  return Promise.resolve()
})

axios.interceptors.response.use(
  response => {
    // console.log(response, 'res')
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default axios