import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import Vant from 'vant'
import 'vant/lib/index.css';
import 'element-plus/dist/index.css'
import router from './router'
import UniswapVue from 'uniswap-vue';
import '@/assets/css/font.css'
import 'animate.css';
import store from './store'

import En from './local/En';
import Arabic from './local/Arabic';


// src/main.js
import { createI18n } from 'vue-i18n';
// src/main.js
const messages = {
    En,   
    Arabic
}
const i18n = createI18n({
  legacy: false,  // 设置为 false，启用 composition API 模式
  messages,
  locale: 'En'  // 设置默认语言
})

createApp(App).use(ElementPlus).use(Vant).use(UniswapVue).use(store).use(router).use(i18n).mount('#app')
// 提供全局方法
