import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import getters from './getters'

export default createStore({
  getters,
  state: {
    list: [],
    data: '',
    id: '',
    activeDrawer: null
  },
  mutations: {
    putList(state, payload) {
      state.list = payload
    },
    putData(state, lll) {
      state.data = lll
    },
    putId(state, p) {
      state.id = p
    },
    setActiveDrawer(state, payload) {
      state.activeDrawer = payload;
    }
  },
  actions: {
  },
  modules: {
    user,
    app,
  }
})
