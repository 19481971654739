import router from '@/router'
import { login, getUserInfo } from '@/api/sys'
import { setItem, getItem, removeItem } from '@/utils/storage'
// import { TOKEN } from '@/constant'
// import { setTimeStamp } from '@/utils/auth'

const TOKEN = 'setToken'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN),
    userInfo: getItem('USER')
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      setItem('USER',userInfo)
    }
  },
  actions: {
    login(context, userInfo) {
      // const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        this.commit('user/setUserInfo', userInfo.user)
        this.commit('user/setToken', userInfo.token)
        resolve()
        // login({
        //   username,
        //   password
        // }).then(res => {
        //   this.commit('user/setToken', 'aaaa-bbb')
        //   router.push('/')
        //   setTimeStamp()
        //   resolve()
        // }).catch(err => {
        //   reject(err)
        // })
      })
    },
    reload(context, userInfo) {
      return new Promise((resolve, reject) => {
        console.log('进入到store中reload')
        this.commit('user/setUserInfo', userInfo)
        resolve()
      })
    },
    async getUserInfo(context) {
      const res = await getUserInfo()
      this.commit('user/setUserInfo', {
        avatar: 'https://misc.360buyimg.com/lib/img/e/logo-201305-b.png',
        id: '1',
        permission: {
          menus: ['userManage', 'roleList', 'articleRanking']
        },
        role: [{id: 1, title: '超级管理员'}],
        title: '超级管理员',
        userName: 'super-admin'
      })
      return res
    },
    logout() {
      console.log('进入到store中logout')
      // this.commit('user/setToken', '')
      // this.commit('user/setUserInfo', null)
      removeItem('USER')
      removeItem('token')
      // removeAllItem()
      // 清理权限
      // router.push('/login')
    }
  }
}