export default {
    desc:`Gulaba is the first fully on-chain mobile in GulabaMeta ecosystem.the world's first tNFT card battle web3 game.`,
    subDesc:`Based on Rollup App, all computational processes and player assets are fully on-chain, ensuring a fair and transparent gaming experience with all game assets owned by the players.`,
    Document:'Document',
    Novel:`GULABA's Official Serialized Nove`,
    game:'Download Now',
    claim:'Claim EGG (Coming soon)',
    Follow:'Follow',
    Join:'Join',
    Join1:'Join Gulaba Now!',
    Hero:'Hero',
    Armor:'Armor',
    Weapon:'Weapon',
    Drone:'Drone',
    Battle:'Battle',
    Scenes:'Scenes',
    soon:'Coming Soon'
}

