import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.loading && $data.requiresLoading ? (_openBlock(), _createBlock(_component_Loading, {
    key: 0
  })) : _createCommentVNode("", true), !$data.loading || !$data.requiresLoading ? (_openBlock(), _createBlock(_component_router_view, {
    key: 1
  })) : _createCommentVNode("", true)]);
}