import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  // {
  //   path: '/',
  //   component: () => import('@/views/index'),
  //   redirect: '/home',
  //   // children: [
  //   //   {
  //   //     path: '/home',
  //   //     name: 'home',
  //   //     component: () => import('@/views/index'),
  //   //     meta: {
  //   //       title: 'home',
  //   //     }
  //   //   },
  //   // ]
  // }
  { path: '/', redirect: '/index' },
  {  
    path: '/index',
    name: 'index',
    component: () => import('@/views/new/home.vue'),
    meta: {
      title: 'index',
      requiresLoading: true, 
    }
  },
  {  
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/privacy/index'),
    meta: {
      title: 'terms',
      requiresLoading: false, // 不需要显示 Loading

    }
  },
  {  
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/privacy.vue'),
    meta: {
      title: 'privacy',
      requiresLoading: false, // 不需要显示 Loading

    }
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, saveScrollPosition) {
    return { left: 0, top: 0 };
  },
})

export default router
