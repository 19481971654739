export default {
  data() {
    return {
      images: [require("@/assets/new/G.png"), require("@/assets/new/U.png"), require("@/assets/new/L.png"), require("@/assets/new/A.png"), require("@/assets/new/B.png"), require("@/assets/new/A1.png")]
    };
  },
  props: {
    isPc: {
      default: false,
      type: Boolean
    }
  },
  mounted() {}
};