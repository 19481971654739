import Loading from "./views/new/components/Loading.vue";
export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      // 初始状态为 true，显示 Loading 组件
      requiresLoading: true // 控制是否需要 Loading 的标志
    };
  },

  mounted() {
    // 在页面加载后，开始监听路由变化
    this.$router.beforeEach((to, from, next) => {
      // 判断目标路由是否需要显示 Loading 组件
      this.requiresLoading = to.meta.requiresLoading !== false;

      // 如果需要显示 Loading，设置 loading 为 true
      if (this.requiresLoading) {
        this.loading = true;
      }
      next(); // 继续路由切换
    });

    this.$router.afterEach(() => {
      // 在路由切换完成后，检查是否需要执行加载逻辑
      if (this.requiresLoading) {
        this.waitForResources().then(() => {
          setTimeout(() => {
            this.loading = false; // 确保至少显示 loading 组件3秒
          }, 4000); // 4秒延时
        });
      } else {
        this.loading = false; // 如果不需要 loading，直接隐藏
      }
    });
  },

  methods: {
    waitForResources() {
      return new Promise(resolve => {
        // 查找当前页面所有的图片
        const images = document.images;
        let loaded = 0;
        if (images.length === 0) {
          resolve(); // 没有图片时，直接结束 loading
        }

        const checkIfComplete = () => {
          loaded++;
          if (loaded === images.length) {
            resolve(); // 当所有图片加载完成时，结束 loading
          }
        };

        // 监听每张图片的加载事件
        for (let i = 0; i < images.length; i++) {
          if (images[i].complete) {
            checkIfComplete(); // 如果图片已加载完成
          } else {
            images[i].addEventListener("load", checkIfComplete); // 图片加载成功
            images[i].addEventListener("error", checkIfComplete); // 图片加载失败
          }
        }
      });
    }
  }
};