

const getters = {
  token: state => localStorage.getItem("setToken"),
  hasUserInfo: state => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  userInfo: state => state.user.userInfo,
}

export default getters