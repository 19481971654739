export default {
  desc: `جورابا هو أول سلسلة كاملة من الألعاب المتنقلة في جورابا metaecosystem . العالم أول بطاقة TNFT الحرب web3 اللعبة .`,
  subDesc: `استنادًا إلى تطبيق Rollup، تكون جميع العمليات الحسابية وأصول اللاعبين متصلة بالكامل بالسلسلة، مما يضمن تجربة لعب عادلة وشفافة مع جميع أصول اللعبة المملوكة للاعبين.`,
  Document: "وثيقة",
  Novel: `نوفمبر الرسمي المتسلسل لـ GULABA`,
  game: "لعبة إصدار الويب (قريبًا)",
  claim: " ( قريبا ) الحصول على EGG!",
  Follow: "يتبع",
  Join: "ينضم",
  Join1: "انضم إلى جولابا الآن!",
  Hero: "البطل .",
  Armor: "آرمور",
  Weapon: "سلاح .",
  Drone: "أزيز",
  Battle: "حرب .",
  Scenes: "مشهد",
  soon: " قريباً",
};
